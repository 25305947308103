<template>
<section class="hero is-medium has-background">
  <div class="hero-body has-text-centered">
  <img src="../../assets/meet.png">
  </div>
</section>

<div class="columns">
  <div class="column">
<div class="card">
  <div class="card-image">
    <figure class="image is-4by3">
      <img src="../../assets/celia.jpg" alt="Placeholder image">
    </figure>
  </div>
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <figure class="image is-48x48">
          <img src="../../assets/celia.jpg" alt="Placeholder image">
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-4">Celia Trevino</p>
        <p class="subtitle is-6"><a href="https://twitter.com/markeetox" target="_blank" class="plain-black-underline">@markeetox</a></p>
      </div>
    </div>

    <div class="content">
      She be the Community Manager and Director of Strategic Initiatives of POTOS. For several years, she was the junior publicist of Latin artists such as Daddy Yankee, Farruko and Elvis Crespo. She also worked as a journalist for Univision online (the top Hispanic media in the USA) covering award ceremonies such as the Latin Grammy. She later became a stay-at-home mom taking care of their little pirates. She fell in love with the NFT world when Gary Vee gifted her her first NFT and joined Markeetox in this journey.
      <br>
    </div>
  </div>
</div>  </div>
  <div class="column">
<div class="card">
  <div class="card-image">
    <figure class="image is-4by3">
      <img src="../../assets/marcos.jpg" alt="Placeholder image">
    </figure>
  </div>
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <figure class="image is-48x48">
          <img src="../../assets/marcos.jpg" alt="Placeholder image">
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-4">Marcos Kwasniewski</p>
        <p class="subtitle is-6"><a href="https://twitter.com/markeetox" target="_blank" class="plain-black-underline">@markeetox</a></p>
      </div>
    </div>

    <div class="content">
     This Scoundrel started on the blockchain world a few months ago. Potos be among the first collections launched. Did a collab with a great group of pirates, and called the crew the NFDaddys and learned a lot about the decentralization. After learning more about the world, Marcos took down Potos and relaunched the collection with the help of Celia, now upgraded (the collection, not her). Community oriented, this pirate be part of yer crew, and be ready to lend a hand (or hook) when needed.
      <br>
    </div>
  </div>
</div>  </div>

</div>



</template>





<script>
export default {
  name: "about",
};
</script>
<style scoped>
</style>